import styled, {css} from 'styled-components';
import {media} from '../../styles/components/media-queries';

import {Button3Inverted} from '../../styles/components/buttons';
import {Icon} from '../../styles/components/icons';

export const Container = styled.article`
  position: relative;
  min-width: 22.8rem;
  max-width: 22.8rem;
  height: 43.5rem;
  max-height: 43.5rem;
  margin: 0.8rem;
  background-color: ${p => p.theme.tertiaryBgColor};
  display: flex;
  flex-direction: column;
  ${media.medium`
    min-width: 16rem;
    max-width: 17.2rem;
    height: 38.5rem
  `};
  ${media.small`
    margin: 0.8rem 0.4rem;
  `};
  ${media.smaller`
    min-width: 46%;
    max-width: 46%;
    margin: 0.5rem 0;
    height: 38.5rem;
  `};
`;

export const LoadingWrapper = styled.div`
  svg {
    width: 3rem;
  }
`;

export const OfferWrapper = styled.header`
  min-height: 3rem;
  background-color: ${p => (p.isOffer ? p.theme.tertiaryColor : 'transparent')};
  display: flex;
  align-items: center;
  position: relative;
`;

export const OfferText = styled.span`
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: ${p => (p.isLimitedSpace || p.isNew ? '1rem' : '2rem')};
  color: ${p => p.theme.primaryTextColor};
  ${media.medium`
    font-size: 1.1rem;
    padding-left: 1rem;
  `};
  ${media.small`
    font-size: 1rem;
    padding-left: ${p => (p.isNew ? '0.6rem' : '1rem')};
  `};
  ${media.smaller`
    font-size: ${p => (p.isNew ? '1.1rem' : '1.2rem')};
  `};
`;

export const NewProduct = styled.span`
  font-size: ${p => (p.isOffer ? '1.2rem' : '1.1rem')};
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 1rem;
  color: ${p => (p.isOffer ? p.theme.primaryTextColor : p.theme.tertiaryColor)};
  ${media.small`  
    font-size: 1rem;
  `};
  ${media.smaller`
    font-size: ${p => (p.isOffer ? '1.1rem' : '1.2rem')};
  `};
`;

export const NewProductTag = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.5rem 1rem;
  color: ${p => p.theme.primaryTextColor};
  background-color: ${p => p.theme.secondaryColor};
`;
export const Like = styled.div`
  cursor: pointer;
  padding-left: 0.5rem;
  ${media.medium`
    &::before {
      font-size: 2rem;
    }
  `};
`;

export const TopButtonGroup = styled.div`
  position: absolute;
  display: flex;
  top: 0.4rem;
  right: 1rem;
  z-index: 99;
  ${media.small`
    right: 0.6rem;
  `};
`;

export const NameWrapper = styled.h3`
  margin: 0;
  width: 100%;
`;

export const Name = styled.a`
  font-size: 1.4rem;
  font-weight: normal;
  margin: 0;
  width: 100%;
  height: 5.8rem;
  max-height: 5.8rem;
  text-align: left;
  color: ${p => p.theme.tertiaryTextColor};
  letter-spacing: -0.3px;
  display: block;
  cursor: pointer;
  overflow: hidden;
  line-height: 1.8rem;
  ${media.medium`
    max-height: 5rem;
    max-height: 5rem;
    font-size: 1.2rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding: 0 1rem;
    line-height: 1.6rem;
  `};
  ${media.smallest`
    font-size: 1.1rem;
  `};
`;
export const SmallName = styled(Name)`
  font-size: 1.3rem;
  height: 5.8rem;
  max-height: 5.8rem;
  margin-bottom: 0.5rem;
`;

export const PhotoWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  cursor: pointer;

  img {
    width: 15.2rem;
    height: 15.2rem;
    min-height: 15.2rem;
    margin-top: 0;
    z-index: 1;
    background: ${p => p.theme.secondaryBgColor};
    ${media.medium`
      width: 12rem;
      height: 12rem;
      min-height: 12rem;
    `};
    ${media.small`
      width: 13rem;
      height: 13rem;
      min-height: 13rem;
      max-height: 13rem;
      margin-left: 0;
    `};
  }
`;

export const ThePhoto = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.section`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding: 0 3rem;
  flex-direction: column;
  ${media.medium`
    padding: 0 0 5rem 0;
  `};
`;

export const Details = styled(Content)`
  padding: 0;
  width: 100%;
  flex-direction: column;
  ${media.small`
    padding-right: 0
  `};
`;

export const Related = styled.div`
  position: absolute;
  top: 1rem;
  right: 0;
  width: 6.6rem;
  height: 10rem;
  z-index: 2;
  background-color: ${p => p.theme.secondaryBgColor};
  text-align: center;
  cursor: pointer;
  ${media.small`
    top: ${p => (p.isListView ? 'auto' : '1rem')};
    bottom: ${p => (p.isListView ? 0 : 'auto')};
    left: ${p => (p.isListView ? 0 : 'auto')};
    display: ${p => (p.isListView ? 'flex' : 'block')};
    height: ${p => (p.isListView ? '6.6rem' : '10rem')};
  `};
`;

export const RelatedPhoto = styled.img`
  width: 5.2rem;
  height: 5.2rem;
  margin: 0.7rem;
`;

export const RelatedText = styled.div`
  font-size: 1.2rem;
  color: ${p => p.theme.tertiaryColor};
`;

export const TextWrapper = styled.div`
  display: block;
  flex-direction: column;
  ${media.small`
    margin-left: ${p => (p.isListView ? '1rem' : 0)};
  `};
`;

export const RelatedId = styled(RelatedText)`
  font-weight: bold;
`;

export const Info = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.medium`
    padding-left: 1rem;
    margin-top: 0;
  `};
`;

export const SmallInfo = styled(Info)`
  ${media.smaller`
    display: flex;
    justify-content: space-between;
  `}
`;

export const Halal = styled(Icon)`
  position: relative;
  background-color: ${p => p.theme.tertiaryColor};

  &::after {
    font-family: 'icomoon';
    content: '\\e903';
    display: inline-block;
    font-size: 1.8em;
    color: ${p => p.theme.primaryTextColor};
  }
`;

export const Id = styled.h4`
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0;
  cursor: pointer;
  color: ${p => p.theme.tertiaryTextColor};
  ${media.medium`
    line-height: 2.6rem;
  `};
  ${media.smaller`
    padding-left: 1rem;
    width: 100%;
  `}
`;

export const Description = styled.span`
  display: ${p => (p.isListView ? 'block' : 'none')};
  font-size: 1.4rem;
  text-align: center;
  color: ${p => p.theme.textColor};
  line-height: 1.7rem;
  min-height: ${p => (p.isListView ? '6.4rem' : '8.2rem')};
  max-height: ${p => (p.isListView ? '6.4rem' : '8.2rem')};
  margin-top: 0.4rem;
  max-width: 100%;
  word-wrap: break-word;
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;
  ${media.medium`
    font-size: 1.2rem;
    min-height: ${p => (p.isListView ? '6rem' : '7.6rem')};
    max-height: ${p => (p.isListView ? '6rem' : '7.6rem')};
    line-height: 1.6rem;
    padding: 0 1rem;
    margin-top: 0;
  `};
`;

export const Weight = styled.span`
  font-size: 1rem;
  text-align: right;
  color: ${p =>
    p.isOffer ? p.theme.tertiaryColor : p.theme.tertiaryTextColor};
  line-height: 1.8rem;
  min-height: 1.8rem;
  margin-top: 0.4rem;
  width: 100%;
  ${media.medium`
    font-size: 0.8rem;
    padding-right: 1rem;
  `};
`;

export const SmallWeight = styled(Weight)`
  font-size: 0.8rem;
  letter-spacing: -0.1px;
`;

export const PriceWrapper = styled.div`
  width: 100%;
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 1rem;
  height: 7.7rem;
  min-height: 7.7rem;
  ${media.medium`
    margin-top: 0;
    margin-bottom: 0;
    height: 2.5rem;
    min-height: 2.5rem;
    padding: 0 1rem 0;
  `};
  ${media.small`
    padding-bottom: 0.5rem;
  `};
`;

export const SmallPriceWrapper = styled(PriceWrapper)`
  height: 7rem;
`;

export const PriceUnavailable = styled.div`
  text-align: ${p => (p.left ? 'left' : 'center')};
  font-size: 1.2rem;
  width: 100%;
  color: ${p => p.theme.tertiaryTextColor};
`;

export const PriceWholeWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 3.4rem;
  justify-content: space-between;
`;

export const Price = styled.div`
  color: ${p => p.theme.tertiaryTextColor};
  padding-bottom: 0.5rem;
  ${media.medium`
   font-size: 1.3rem;
  `};
  ${media.smallest`
    font-size: 1.2rem;
  `};
`;

export const PriceType = styled.div`
  font-size: 0.9rem;
  text-transform: uppercase;
  text-align: left;
  color: ${p => (p.isOffer ? p.theme.tertiaryColor : p.theme.primaryBgColor)};
`;

export const PriceText = styled.div`
  font-size: 1.8rem;
  text-align: ${p => (p.alignRight ? 'right' : 'left')};
  color: ${p => (p.isOffer ? p.theme.tertiaryColor : p.theme.quinaryTextColor)};
  ${media.medium`
   font-size: 1.6rem;
  `};
`;
export const UnitPriceText = styled.div`
  font-size: 1rem;
  font-weight: bold;
  text-align: ${p => (p.alignRight ? 'right' : 'left')};
  color: ${p =>
    p.isOffer ? p.theme.tertiaryColor : p.theme.tertiaryTextColor};
  margin-top: 3rem;
`;

export const OutOfStockText = styled.div`
  color: ${p => p.theme.primaryColorHover};
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  ${media.medium`
   font-size: 1.2rem;
   height: 1.2rem;
  `};
  ${media.smallest`
    font-size: 1rem;
    height: 1rem;
  `};
`;

export const OutOfStockButtonText = styled(OutOfStockText)`
  display: flex;
  height: 4rem;
  min-height: 4rem;
  align-items: center;
  justify-content: center;
`;

export const AvailableFromButtonText = styled(OutOfStockButtonText)`
  color: ${p => p.theme.primaryColorHover};
`;

export const ExtraPromo = styled.div`
  color: ${p => p.theme.tertiaryColor};
  font-size: 1.6rem;
  text-transform: uppercase;
  height: 3.2rem;
  ${media.medium`
   padding-left: 1rem;
   font-size: 1.4rem;
  `};
`;

export const MultiBuyOffer = styled.span`
  ${media.smaller`
    display: none;
  `};
`;

export const ExtraPromoBig = styled.span`
  font-size: 2.8rem;
  text-transform: lowercase;
  font-weight: bold;
  ${media.medium`
    font-size: 2.4rem;
  `};
`;

export const ExtraPromoSmaller = styled(ExtraPromoBig)`
  font-size: 2.4rem;
  line-height: 3.5rem;
  ${media.medium`
    font-size: 2rem;
    line-height: 3rem;
  `};
`;

export const PriceBig = styled.span`
  line-height: 3.2rem;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  color: ${p => p.theme.tertiaryColor};
`;

export const ExtraPromoSmall = styled(ExtraPromoBig)`
  font-size: 2.4rem;
  ${media.medium`
    font-size: 2.2rem;
  `};
`;

export const Tags = styled.div`
  width: 100%;
  position: absolute;
  bottom: -0.8rem;
`;

export const TagsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const theOuterStyle = css`
  margin-top: 0;
  ${media.medium`
     margin-top: 0;
  `};
  ${media.small`
    height: 3rem;
    min-height: 3rem;
  `};
`;

export const AddButton = styled(Button3Inverted)`
  ${theOuterStyle};
  ${media.small`
    max-width: 25rem;
  `};
`;

export const QuantityWrapper = styled.div`
  ${theOuterStyle};
  width: 100%;
`;

export const ButtonStyled = styled.footer`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 1.4rem;
  padding: 0 2rem 1rem;
  ${media.medium`
    padding: 0 1rem 1rem;
  `};

  a {
    width: 100%;
  }

  ${media.small`
    a {
      width: 100%;
    }
  `};
  ${media.smaller`
    font-size: 1.2rem;
  `};
`;

export const PromoWrapper = styled.div`
  text-align: center;
  margin: 0;
  overflow: hidden;
  ${media.smaller`
    overflow: visible;
  `};
`;

export const SubQuantityWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 0.5rem;
  width: 100%;
  justify-content: center;
  z-index: 10;
`;
