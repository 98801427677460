import React, {Fragment, PureComponent} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {captureException} from '@sentry/browser';
import AdyenCheckout from '@adyen/adyen-web';

import {getApiConfig} from '../../config/configProvider';
import {
  getDataFromLocalStorage,
  removeValueFromLocalStorage,
} from '../../helpers/localStorage.helper';
import {
  JJ_LOCAL_STORAGE,
  ROUTES,
  SLOT_FULFILMENT_STATUSES,
  STOCK_CHECK,
  SERVICE_TYPE,
  CHECKOUT_RESULT,
  BASKET_SYNC_TIMEOUT,
  A_HOUR,
  MANUALLY_ERRORS,
  BRAINTREE_TYPES,
  BUTTON_NAME,
  AUTH_ORIGIN,
  ORDER_SOURCE,
  PAYMENT_ERROR_MESSAGE,
  A_DAY,
  JJ_SESSION_STORAGE,
  ADYEN_ERROR,
} from '../../constants/constants';
import {
  Wrapper,
  Title,
  Head,
  ButtonWrapper,
  LinkButton,
} from '../../styles/pages/pages';
import {
  authAdyenPayment,
  authBraintreePayment,
  authorizeOrder,
  getCheckoutData,
  resetCheckout,
} from '../../actions/checkout.action';
import {
  CheckoutContent,
  ContentWrapper,
  BorderContent,
  Block,
  TheButton,
  LoadingWrapper,
  ButtonLoading,
  HiddenButton,
  GpIframe,
  BraintreeDropIn,
  PaymentDetailsText,
  ContentLine,
  ChangeButtonWrapper,
  LeftBlock,
  PaymentDetailsContent,
  AdyenContent,
  PaymentAmount,
  AydenWrapper,
  DelayAuth,
} from './CheckoutPage.style';
import PriceSummary from '../../components/PriceSummary/PriceSummary';
import {ExtraWrapper} from '../../styles/components/wrapper';
import Loading from '../../components/Loading/Loading';
import {
  checkBasketSynced,
  getBasket,
  setCurrentRoute,
  setPaymentType,
} from '../../actions';
import PopupModal from '../../components/PopupModal/PopupModal';
import BookSlotStatus from '../../components/BookSlotStatus/BookSlotStatus';
import {resetError, setErrorManually} from '../../actions/error.action';
import CheckoutErrorsModal from '../../components/PopupModal/CheckoutErrorsModal';
import {ChangeSlot, ContentBlock} from '../BasketPage/BasketPage.style';
import {PAYMENT_TYPES} from '../../constants/constants';
import {getPaymentDetails, setAccount} from '../../actions/accounts.action';
import {
  getTokenByPassword,
  removeAuth,
  removeLoginToken,
  requestForceLogin,
  setAuth,
  setLoginToken,
} from '../../actions/auth.action';
import {
  checkHasCardPayment,
  hasMinDeliveryOrderNotMet,
  checkHasPbbPayment,
  checkIsDev,
  addTwoValues,
} from '../../helpers/data.helper';
import {resetLocalStorage} from '../../helpers/localStorage.helper';
import PaymentSelector from '../../components/PaymentSelector/PaymentSelector';
import {GoogleAnalyticsHelper} from '../../helpers/googleAnalytics.helper';
import {
  checkSlotExpired,
  getSlotTimesLeft,
  isBasketSynced,
} from '../../helpers/basket.helper';
import CashPaymentModal from '../../components/PopupModal/CashPaymentModal';
import PaymentErrorModal from '../../components/PopupModal/PaymentErrorModal';
import ReLoginModal from '../../components/LoginRegister/ReLoginModal';
import {
  togglePaymentDetails,
  toggleReloginModel,
} from '../../actions/modal.action';
import * as dropin from 'braintree-web-drop-in';
import {getBraintreeInitObj} from '../../helpers/braintree.helper';
import {Button} from '../../components/PriceSummary/PriceSummary.style';
import {getLoginParams} from '../../helpers/azure.helper';
import {getPbbBankDetails} from '../../actions/pbbBankDetails.action';
import CardPaymentModal from '../../components/PopupModal/CardPaymentModal';
import {persistDataToSessionStorage} from '../../helpers/sessionStorage.helper';
import {getPaymentMethodsConfiguration} from '../../constants/adyen.constants';
import PaymentCommercialCardModal from '../../components/PopupModal/PaymentCommercialCardModal';

class CheckoutPage extends PureComponent {
  constructor(props) {
    super(props);
    const config = getApiConfig();
    this.realexPaymentUrl = config.realexPayment;
    this.isCheckoutTracked = false;
    this.isSyncingBasket = false;
    this.isCleaningInverval = false;
    this.showDailyLimit = props.route !== ROUTES.BASKET;
    this.payButtonRef = React.createRef();
    this.basketMinOrderError = false;
    this.processingOrder = false;
    this.scrollRef = null;
    this.redirectInterval = null;
    this.braintreeInstance = null;
    this.sendingNonce = false;
    this.isGettingDefaultPbbBank = false;

    this.state = {
      basketId: null,
      loading: true,
      placingOrder: false,
      reloginRedirectUrl: null,
      paymentError: null,
      dailyLimitNum: null,
      paymentType:
        (props.basket && props.basket.paymentMethod) || PAYMENT_TYPES.CDC.mode,
      showCashPaymentModal: false,
      showCardPaymentModal: false,
      hasCardPayment: null,
      hasPbbPayment: null,
      editOrderOriginalPaymentMethod: null,
      showGpIframe: false,
      showBtDropin: false,
      showAdyenDropin: false,
      isProcessingAdyen: false,
      showBraintreeButton: false,
      reGetCheckoutData: false,
      isSubmittingPayment: false,
      paymentTotalAmount: null,
    };
  }

  static getDerivedStateFromProps(props, prevState) {
    const {location, settings, nextRoute} = props;
    if (location && location.pathname && !prevState.basketId) {
      return {
        basketId: location.pathname.replace('/checkout/', ''),
      };
    }
    if (
      location &&
      location.state &&
      location.state.paymentError !== prevState.paymentError
    ) {
      return {
        paymentError: location.state.paymentError,
      };
    }
    return null;
  }

  async componentDidMount() {
    const lastAuthTimestamp = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.LAST_AUTH_TIME
    );
    const {
      basket,
      getBasket,
      auth,
      setCurrentRoute,
      location,
      promoTotal,
    } = this.props;
    setCurrentRoute(ROUTES.CHECKOUT);
    // we should not redirect to basket page if there is a payment error
    if (!auth && !(location.state && location.state.paymentError)) {
      setCurrentRoute(ROUTES.BASKET);
      this.navigateToBasket();
    }
    if (
      (!lastAuthTimestamp && auth && auth.origin === AUTH_ORIGIN.REFRESH) ||
      new Date().getTime() - lastAuthTimestamp > A_DAY
    ) {
      this.props.toggleReloginModel(true);
      this.setState({loading: false});
      return;
    }
    // todo: hack for making sure getCheckoutData request will be fetched by saga
    if (basket) {
      // get enriched basket if we don't have `promoTotal` in case the user goes to
      // checkout directly - instead of through the basket page
      const enrichedBasket = !promoTotal;
      getBasket(enrichedBasket);
    }
    const editOrderOriginalPaymentMethod = await getDataFromLocalStorage(
      JJ_LOCAL_STORAGE.EDIT_ORDER_PAYMENT_METHOD
    );
    this.setState({
      editOrderOriginalPaymentMethod,
    });
    this.redirectInterval = setTimeout(this.navigateToBasket, A_HOUR);
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      auth,
      basket,
      checkout,
      route,
      profile,
      settings,
      history,
      order,
      loadingProps,
      setErrorManually,
      checkBasketSynced,
      modal,
      errors,
      paymentDetails,
      nextRoute,
      getPbbBankDetails,
      pbbDefaultBank,
      authAdyenPayment,
      adyen,
    } = this.props;
    if (modal && modal.relogin) {
      return;
    }
    if (nextRoute !== prevProps.nextRoute) {
      if (nextRoute.indexOf(`/confirmation/`) > -1) {
        removeValueFromLocalStorage(JJ_LOCAL_STORAGE.FULFILLMENT);
        if (nextRoute.indexOf('&fromApp=true') === -1) {
          history.push({
            pathname: nextRoute,
            state: {
              axBasketId: order.axBasketId,
              orderId: order.orderId,
            },
          });
        }
      }
      if (nextRoute.indexOf(`adyen.com/`) > -1) {
        window.location = nextRoute;
      }
    }
    if (profile && profile.paymentOptions) {
      if (this.state.hasCardPayment === null) {
        const hasCardPayment = checkHasCardPayment(profile.paymentOptions);
        this.setState({hasCardPayment});
      }
      if (this.state.hasPbbPayment === null) {
        const hasPbbPayment = checkHasPbbPayment(profile.paymentOptions);
        this.setState({hasPbbPayment});
      }
    }
    if (
      basket &&
      prevProps.basket &&
      !this.basketMinOrderError &&
      (basket.minimumOrderAmount !== prevProps.basket.minimumOrderAmount ||
        (isBasketSynced(basket.syncStatus) &&
          !isBasketSynced(prevProps.basket.syncStatus)))
    ) {
      const isCheckoutPage = true;
      const minOrderNotMeet = hasMinDeliveryOrderNotMet(basket, isCheckoutPage);
      if (minOrderNotMeet) {
        setErrorManually(MANUALLY_ERRORS.BASKET_MIN_DELIVERY_ORDER_ERROR);
        this.basketMinOrderError = true;
        this.navigateToBasket();
        return;
      }
    }
    if (
      settings &&
      settings.pbbDefaultBankId &&
      !pbbDefaultBank &&
      !this.isGettingDefaultPbbBank
    ) {
      this.isGettingDefaultPbbBank = true;
      getPbbBankDetails(settings.pbbDefaultBankId);
    }
    if (
      basket &&
      basket.items &&
      basket.items.length > 0 &&
      !this.state.dailyLimitNum
    ) {
      this.setState({
        dailyLimitNum: basket.items.filter(
          item =>
            item.stockCheck &&
            item.stockCheck.result === STOCK_CHECK.DAILY_LIMIT
        ).length,
      });
    }
    // requires to sync basket again
    if (
      basket &&
      prevProps.basket &&
      basket.paymentMethod !== prevProps.basket.paymentMethod
    ) {
      this.isSyncingBasket = false;
    }
    if (basket && !isBasketSynced(basket.syncStatus) && !this.isSyncingBasket) {
      this.isSyncingBasket = true;
      this.isCleaningInverval = false;
      this.setState({loading: true});
      this.interval = setInterval(
        () => checkBasketSynced(),
        BASKET_SYNC_TIMEOUT
      );
    }
    // reset values to default if no basket found
    if (!basket) {
      this.setState({loading: false});
      this.isSyncingBasket = false;
      this.processingOrder = false;
    }
    // clear interval after basket is set
    if (
      basket &&
      isBasketSynced(basket.syncStatus) &&
      this.isSyncingBasket &&
      !this.processingOrder
    ) {
      this.setState({loading: false});
      clearInterval(this.interval);
      this.isSyncingBasket = false;
      this.processingOrder = false;
    }
    if (
      basket &&
      this.state.paymentType !== basket.paymentMethod &&
      this.processingOrder
    ) {
      this.processingOrder = false;
      this.setState({loading: true});
    }
    if (order && !prevProps.order && prevState.loading) {
      this.processingOrder = false;
      this.setState({loading: false});
    }
    if (
      auth &&
      auth.origin === AUTH_ORIGIN.ENHANCED &&
      prevProps.auth &&
      prevProps.auth.origin === AUTH_ORIGIN.REFRESH
    ) {
      // start processing Checkout after relogin password
      this.initCheckout();
    }
    if (
      modal &&
      !modal.paymentDetails &&
      prevProps.modal &&
      prevProps.modal.paymentDetails
    ) {
      if (
        basket &&
        (basket.paymentMethod === PAYMENT_TYPES.CDC.mode ||
          basket.paymentMethod === PAYMENT_TYPES.BCDC.mode) &&
        paymentDetails &&
        paymentDetails !== prevProps.paymentDetails
      ) {
        this.initCheckout();
      }
    }
    if (
      basket &&
      basket.uuid &&
      isBasketSynced(basket.syncStatus) &&
      ((!this.processingOrder &&
        loadingProps &&
        !loadingProps.updatingBasket &&
        !this.isSyncingBasket &&
        this.state.loading) ||
        this.state.reGetCheckoutData)
    ) {
      this.initCheckout();
    }
    if (errors && errors.braintree && this.state.isSubmittingPayment) {
      this.sendingNonce = false;
      this.setState({isSubmittingPayment: false});
    }
    if (errors && errors.adyen) {
      window.isSubmittingAdyen = false;
      window.isSubmittingAddtional = false;
      this.adyenDropin.update();
    }
    if (
      checkout &&
      checkout.result === CHECKOUT_RESULT.PAYMENT_HOSTED_PAGE &&
      order &&
      RealexHpp &&
      loadingProps &&
      !loadingProps.loadingCheckout &&
      !loadingProps.settings &&
      (order.paymentMethod === PAYMENT_TYPES.CDC.mode ||
        order.paymentMethod === PAYMENT_TYPES.BCDC.mode)
    ) {
      RealexHpp.setHppUrl(this.realexPaymentUrl);
      RealexHpp.embedded.init(
        'checkoutButton',
        'gpIframe',
        checkout.responseUrl,
        checkout.requestJson
      );
    }
    if (
      checkout &&
      checkout.result === CHECKOUT_RESULT.PAYMENT_BRAINTREE &&
      order &&
      (order.paymentMethod === PAYMENT_TYPES.CDC.mode ||
        order.paymentMethod === PAYMENT_TYPES.BCDC.mode)
    ) {
      this.setState({showBtDropin: true});
    }
    if (adyen && adyen !== prevProps.adyen) {
      this.adyenDropin.handleAction(adyen);
    }
    if (
      route === ROUTES.CHECKOUT &&
      checkout &&
      !this.state.isProcessingAdyen &&
      checkout.result === CHECKOUT_RESULT.PAYMENT_ADYEN &&
      order &&
      (order.paymentMethod === PAYMENT_TYPES.CDC.mode ||
        order.paymentMethod === PAYMENT_TYPES.BCDC.mode)
    ) {
      this.setState({isProcessingAdyen: true, showAdyenDropin: true});
      if (checkout.paymentMethods && checkout.responseUrl) {
        let paymentMethodsResponse;
        try {
          paymentMethodsResponse = JSON.parse(checkout.paymentMethods);
        } catch (e) {
          captureException(e);
        }
        if (!paymentMethodsResponse) {
          return;
        }
        const configuration = {
          paymentMethodsResponse,
          clientKey: checkout.clientKey,
          locale: 'en-GB',
          environment: checkIsDev(),
          analytics: {
            enabled: true,
          },
          onSubmit: (state, dropin) => {
            if (window.isSubmittingAdyen) {
              return;
            }
            window.isSubmittingAdyen = true;
            authAdyenPayment(checkout.responseUrl, state.data, order.orderId);
          },
          onAdditionalDetails: (state, dropin) => {
            if (window.isSubmittingAdyenAdditonal) {
              return;
            }
            window.isSubmittingAdyenAdditonal = true;
            const isAdditionalDetails = true;
            authAdyenPayment(
              checkout.responseUrl,
              state.data,
              order.orderId,
              isAdditionalDetails
            );
          },
          paymentMethodsConfiguration: getPaymentMethodsConfiguration(
            !!checkout.hideCvc
          ),
        };
        const checkoutAdyen = await AdyenCheckout(configuration);
        this.adyenDropin = checkoutAdyen
          .create('dropin', {
            openFirstPaymentMethod: true,
            showRemovePaymentMethodButton: true,
            onDisableStoredPaymentMethod: (
              storedPaymentMethodId,
              resolve,
              reject
            ) => {
              authAdyenPayment(
                checkout.responseUrl,
                null,
                order.orderId,
                false,
                {
                  storedPaymentMethodId,
                  resolve,
                  reject,
                }
              );
            },
          })
          .mount('#dropin-adyen');
      }
    }
    if (
      checkout &&
      checkout.result === CHECKOUT_RESULT.PAYMENT_ECOSPEND &&
      order
    ) {
      this.setState({showRedirectButton: true});
    }
    if (
      order &&
      order.paymentMethod !== PAYMENT_TYPES.CDC.mode &&
      order.paymentMethod !== PAYMENT_TYPES.BCDC.mode &&
      order.paymentMethod !== PAYMENT_TYPES.PBB.mode &&
      this.state.showBtDropin
    ) {
      this.setState({showBtDropin: false});
    }
    if (
      order &&
      order.paymentMethod !== PAYMENT_TYPES.CDC.mode &&
      order.paymentMethod !== PAYMENT_TYPES.BCDC.mode &&
      order.paymentMethod !== PAYMENT_TYPES.PBB.mode &&
      this.state.showAdyenDropin
    ) {
      this.setState({showAdyenDropin: false});
    }
    if (
      order &&
      order.paymentMethod !== PAYMENT_TYPES.CDC.mode &&
      order.paymentMethod !== PAYMENT_TYPES.BCDC.mode &&
      order.paymentMethod !== PAYMENT_TYPES.PBB.mode &&
      !!this.state.showGpIframe
    ) {
      this.setState({showGpIframe: false});
    }
    if (
      basket &&
      basket.items &&
      basket.items.length > 0 &&
      !this.isCheckoutTracked
    ) {
      this.isCheckoutTracked = true;
    }
    if (this.state.timeout <= 0) {
      this.navigateToBasket();
    }
    if (checkout && checkout.amount && !this.state.paymentTotalAmount) {
      this.setState({paymentTotalAmount: checkout.amount});
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.rxpTimeout);
    clearInterval(this.interval);
    clearTimeout(this.redirectInterval);
    clearTimeout(this.btTimeout);
    this.props.resetCheckout();
    this.scrollRef = null;
  }

  initCheckout = () => {
    const {auth} = this.props;
    this.isSyncingBasket = true;
    this.processingOrder = true;
    this.setState({
      loading: true,
      reGetCheckoutData: false,
      showGpIframe: false,
      showBtDropin: false,
      showAdyenDropin: false,
      showBraintreeButton: false,
      showRedirectButton: false,
      isProcessingAdyen: false,
    });
    window.isSubmittingAdyen = false;
    window.isSubmittingAdyenAdditonal = false;
    if (auth) {
      this.processCheckout(this.state.paymentType);
    }
  };

  processCheckout = paymentType => {
    const {
      getCheckoutData,
      basket,
      order,
      settings,
      getPaymentDetails,
      paymentDetails,
    } = this.props;
    const isBrainTree = !!(
      settings &&
      settings.braintree === 'true' &&
      settings.adyen !== 'true'
    );
    const isEcoSpend = !!(settings && settings.allowEcospend === 'true');
    if (basket && basket.uuid) {
      if (
        (basket.paymentMethod === PAYMENT_TYPES.CDC.mode ||
          basket.paymentMethod === PAYMENT_TYPES.BCDC.mode) &&
        paymentDetails &&
        paymentDetails.confirmed
      ) {
        getPaymentDetails();
      }
      getCheckoutData(
        basket.uuid,
        basket.paymentMethod,
        isBrainTree,
        isEcoSpend,
        ORDER_SOURCE.WEB
      );
      GoogleAnalyticsHelper.trackPayment(basket.items, paymentType);
    } else {
      captureException(
        new Error(`no basket uuid on checkout page + ${order && order.orderId}`)
      );
    }
  };
  showSlotExpired = () =>
    this.props.setErrorManually(MANUALLY_ERRORS.CHECKOUT_SLOT_EXPIRED);

  handleBraintreeFailed = errors => {
    this.setState({isSubmittingPayment: false});
    this.sendingNonce = false;
    if (
      this.braintreeInstance &&
      this.braintreeInstance.clearSelectedPaymentMethod
    ) {
      this.braintreeInstance.clearSelectedPaymentMethod();
    }
    this.props.setErrorManually(
      MANUALLY_ERRORS.AUTHORIZE_BRAINTREE_PAYMENT_FAILED,
      [errors]
    );
    GoogleAnalyticsHelper.trackBraintreeError(errors);
  };

  sendNonceToServer = () => {
    if (this.sendingNonce) {
      return;
    }
    this.sendingNonce = true;
    const {checkout, order, authBraintreePayment} = this.props;
    if (!this.braintreeInstance) {
      this.handleBraintreeFailed({
        message: PAYMENT_ERROR_MESSAGE.GENERAL,
      });
      return;
    }
    this.braintreeInstance.requestPaymentMethod(
      {
        threeDSecure: checkout.threeDSecureParameters,
      },
      (requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          this.handleBraintreeFailed(requestPaymentMethodErr);
          return;
        }
        if (payload && payload.nonce) {
          this.setState({isSubmittingPayment: true});
          authBraintreePayment(
            checkout.responseUrl,
            {
              nonce: payload.nonce,
              deviceData: payload.deviceData,
            },
            order.orderId
          );
        } else {
          this.handleBraintreeFailed({
            message: PAYMENT_ERROR_MESSAGE.ANOTHER_METHOD,
          });
        }
      }
    );
  };

  processCardPayment = e => {
    const {
      checkout,
      basket,
      deviceInfo,
      order,
      paymentDetails,
      promoTotal,
    } = this.props;
    const {hasPbbPayment, showCardPaymentModal} = this.state;
    if (
      paymentDetails &&
      !paymentDetails.confirmed &&
      order &&
      (order.paymentMethod === PAYMENT_TYPES.CDC.mode ||
        order.paymentMethod === PAYMENT_TYPES.BCDC.mode)
    ) {
      this.openPaymentDetails();
      return;
    }
    const isSlotExpired = checkSlotExpired(basket);
    if (isSlotExpired && isSlotExpired.slotExpired) {
      this.showSlotExpired();
      return;
    }
    const hasPBBPromo = basket && promoTotal && promoTotal.payByBank > 0;
    if (
      hasPbbPayment &&
      hasPBBPromo &&
      !showCardPaymentModal &&
      order &&
      (order.paymentMethod === PAYMENT_TYPES.CDC.mode ||
        order.paymentMethod === PAYMENT_TYPES.BCDC.mode)
    ) {
      this.setState({showCardPaymentModal: true});
      return;
    } else {
      this.setState({showCardPaymentModal: false});
    }
    const countingDown = getSlotTimesLeft(basket, order);
    if (countingDown > 0 && window) {
      // redirect to payment page if result is redirect
      if (
        checkout &&
        checkout.result === CHECKOUT_RESULT.PAYMENT_ECOSPEND
        // && order &&
        // order.paymentMethod === PAYMENT_TYPES.PBB.mode
      ) {
        window.location.href = checkout.redirectUrl;
        return;
      }
      if (checkout && checkout.result === CHECKOUT_RESULT.PAYMENT_BRAINTREE) {
        this.setState({showBraintreeButton: true}, () => {
          try {
            const finalPrice = (order.price && order.price.total) || 0;
            const dropinObj = getBraintreeInitObj(
              checkout.clientToken,
              finalPrice,
              checkout.allowPaypal,
              checkout.allowApplePay,
              checkout.allowGooglePay
            );
            dropin.create(dropinObj, (createErr, instance) => {
              if (!instance) {
                return;
              }
              this.braintreeInstance = instance;
              this.btTimeout = setTimeout(() => {
                this.braintreeInstance && this.braintreeInstance.teardown();
                this.showSlotExpired();
              }, countingDown);
              try {
                this.braintreeInstance.on('paymentOptionSelected', data => {
                  if (
                    data &&
                    (data.paymentOption === BRAINTREE_TYPES.APPLE_PAY ||
                      data.paymentOption === BRAINTREE_TYPES.GOOGLE_PAY)
                  ) {
                    this.setState({showBraintreeButton: false});
                  }
                });
                this.braintreeInstance.on('noPaymentMethodRequestable', () => {
                  this.setState({showBraintreeButton: false});
                });
                this.braintreeInstance.on('paymentMethodRequestable', event => {
                  if (
                    event &&
                    event.paymentMethodIsSelected &&
                    (event.type === BRAINTREE_TYPES.APPLE_PAY_CARD ||
                      event.type === BRAINTREE_TYPES.ANDROID_PAY_CARD)
                  ) {
                    this.sendNonceToServer();
                  } else if (!this.state.showBraintreeButton) {
                    this.setState({showBraintreeButton: true});
                  }
                });
              } catch (e) {
                this.handleBraintreeFailed(e);
              }
            });
          } catch (e) {
            this.handleBraintreeFailed(e);
          }
        });
      } else {
        let rxp;
        try {
          rxp = RealexHpp.getInstance();
        } catch (e) {
          captureException(e);
        }
        this.rxpTimeout = setTimeout(() => {
          rxp && rxp.close();
          this.showSlotExpired();
        }, countingDown);
      }
    } else {
      this.setState({showBraintreeButton: false});
      this.showSlotExpired();
      return;
    }
    if (
      checkout &&
      checkout.result === CHECKOUT_RESULT.PENDING_AUTHORIZE &&
      !checkout.authorizeUrl &&
      !checkout.amount
    ) {
      const directCardPayment = true;
      this.processDirectPayment(directCardPayment);
    } else {
      if (checkout && checkout.result === CHECKOUT_RESULT.PAYMENT_BRAINTREE) {
        return;
      }
      // open the GP iframe while its not a direct payment
      this.setState({showGpIframe: true}, () => {
        if (deviceInfo && deviceInfo.isPhone) {
          this.scrollRef.scrollIntoView({behavior: 'smooth'});
        }
        this.payButtonRef.current.click(e);
      });
    }
  };

  hideCashPaymentModal = () => this.setState({showCashPaymentModal: false});
  hideCardPaymentModal = () => this.setState({showCardPaymentModal: false});

  onNonCardPaymentClick = () => {
    const {basket, order} = this.props;
    const countingDown = getSlotTimesLeft(basket, order);
    if (countingDown < 0) {
      this.showSlotExpired();
      return;
    }
    if (order && order.paymentMethod === PAYMENT_TYPES.CAS.mode) {
      this.setState({showCashPaymentModal: true});
    } else {
      this.processNoCardPayment();
    }
  };

  processNoCardPayment = () => this.processDirectPayment();

  processDirectPayment = directCardPayment => {
    const {basketId, placingOrder} = this.state;
    if (placingOrder) {
      return;
    }
    const {auth, order, basket, authorizeOrder} = this.props;
    if (!directCardPayment) {
      const isSlotExpired = checkSlotExpired(basket);
      if (isSlotExpired && isSlotExpired.slotExpired) {
        this.showSlotExpired();
        return;
      }
    }
    this.setState({placingOrder: true});
    if (!basketId || !auth || !order) {
      this.setState({placingOrder: false});
      return;
    }
    authorizeOrder(order, basket);
    GoogleAnalyticsHelper.trackPayment(basket.items, basket.paymentMethod);
  };

  bookSlot = () => this.props.history.push(`/bookSlot`);

  navigateToBasket = () => this.props.history.push(`/basket`);

  resetServerErrors = () => {
    const {errors, resetError, requestForceLogin} = this.props;
    resetError();
    if (errors && errors.processCheckout) {
      requestForceLogin();
      const loginRegisterParams = getLoginParams(
        window.location.hostname,
        null,
        null
      );
      try {
        persistDataToSessionStorage(
          JJ_SESSION_STORAGE.VERIFIER,
          loginRegisterParams.verifier
        );
      } catch (e) {
        setErrorManually(e);
      }
      window.location = loginRegisterParams.authUrl;
    } else {
      this.navigateToBasket();
    }
  };

  resetBraintreeErrors = () => {
    this.setState({processBraintree: false});
    this.props.resetError();
  };

  resetAdyenErrors = () => this.props.resetError();

  processCommercialCard = () => {
    const {resetError, checkout, authAdyenPayment, order, errors} = this.props;
    if (errors && errors.adyen && errors.adyen.surcharge) {
      const paymentTotalAmount = addTwoValues(
        this.state.paymentTotalAmount,
        errors.adyen.surcharge
      );
      this.setState({paymentTotalAmount});
    }
    resetError();
    authAdyenPayment(checkout.responseUrl, {type: 'ADYEN'}, order.orderId);
  };

  changeToCardPayment = () => {
    const {basket, setPaymentType} = this.props;
    if (!basket) {
      return;
    }
    setPaymentType({
      paymentMethod: PAYMENT_TYPES.CDC.mode,
    });
  };

  changeToPbbPayment = () => {
    const {basket, setPaymentType} = this.props;
    if (!basket) {
      return;
    }
    setPaymentType({
      paymentMethod: PAYMENT_TYPES.PBB.mode,
    });
  };

  resetPaymentError = () => {
    const {history, location, basket, getBasket, settings} = this.props;
    if (!basket) {
      // payment failed will redirect to checkout page and it wont reload the
      // basket because app.js will not get the basket in checkout page
      getBasket();
    }
    history.replace(location.pathname, {});
    this.setState({paymentError: null});
  };

  onPaymentErrorCallback = () => {
    this.resetPaymentError();
    this.setState({reGetCheckoutData: true});
    this.props.getPaymentDetails();
    this.navigateToBasket();
  };

  logout = () => {
    const {removeAuthToken, history, removeLoginToken} = this.props;
    resetLocalStorage();
    removeAuthToken();
    removeLoginToken();
    history.push('/');
  };

  setRef = node => (this.scrollRef = node);

  closeReLoginModal = () => this.props.toggleReloginModel(false);

  closeReLoginModalCallback = () => {
    this.navigateToBasket();
    this.closeReLoginModal();
  };

  openPaymentDetails = () => this.props.togglePaymentDetails(true);

  render() {
    const {
      basket,
      errors,
      branchList,
      auth,
      profile,
      order,
      loadingProps,
      promoTotal,
      modal,
      resetError,
      reloginByPassword,
      paymentDetails,
      isStaffAccount,
      settings,
      checkout,
    } = this.props;
    const {
      loading,
      isSubmittingPayment,
      showCashPaymentModal,
      showCardPaymentModal,
      hasPbbPayment,
      showGpIframe,
      showBtDropin,
      showAdyenDropin,
      showBraintreeButton,
      paymentTotalAmount,
    } = this.state;
    const renderReLoginModal = modal && modal.relogin && auth && (
      <ReLoginModal
        showModal={modal.relogin}
        auth={auth}
        errors={errors}
        resetError={resetError}
        toggleModal={this.closeReLoginModal}
        closeModal={this.closeReLoginModalCallback}
        loading={loadingProps}
        logout={this.logout}
        submitPassword={reloginByPassword}
      />
    );
    const renderProcessingModal = loading &&
      errors &&
      !errors.processCheckout && (
        <PopupModal
          content={'In a moment you can review and place your order'}
          title={'Checking your Order'}
          isError={false}
          showModal={loading}
          isLoadingModal={true}
          showCancelButton={true}
          cancelCallBack={this.navigateToBasket}
        />
      );
    const renderSubmittingPaymentModal = isSubmittingPayment && (
      <PopupModal
        content={'While we process your payment'}
        title={'Please wait'}
        isError={false}
        showModal={isSubmittingPayment}
        isLoadingModal={true}
      />
    );
    const renderServerErrors = errors &&
      (errors.basket || errors.processCheckout) && (
        <PopupModal
          modalName="Process Checkout Error"
          content={
            errors.processCheckout
              ? 'Process checkout error, please try again.'
              : errors.basket
          }
          title={'Error'}
          isError={true}
          showModal={!!errors.basket || !!errors.processCheckout}
          callback={this.resetServerErrors}
          showOKButton={true}
          showCancelButton={false}
          closeModalCallback={this.resetServerErrors}
        />
      );
    const renderBraintreeErrors = errors &&
      errors.braintree &&
      errors.braintree.length > 0 && (
        <PaymentErrorModal
          isBraintree={true}
          errors={errors.braintree}
          showModal={errors.braintree && errors.braintree.length > 0}
          callback={this.resetBraintreeErrors}
        />
      );
    const renderAdyenErrors = errors &&
      !!errors.adyen &&
      !errors.adyen[ADYEN_ERROR.COMMERCIAL_CARD] && (
        <PaymentErrorModal
          errors={errors.adyen}
          showModal={!!errors.adyen}
          callback={this.resetAdyenErrors}
        />
      );
    const renderAdyenCommercialCard = errors &&
      !!errors.adyen &&
      errors.adyen[ADYEN_ERROR.COMMERCIAL_CARD] && (
        <PaymentCommercialCardModal
          showModal={true}
          closeModalCallback={this.resetAdyenErrors}
          callback={this.processCommercialCard}
          commercialCardFee={errors.adyen.surcharge}
          amount={checkout && checkout.amount}
        />
      );
    const renderCheckoutErrors = errors && errors.checkout && (
      <CheckoutErrorsModal
        errors={errors.checkout}
        showModal={!!errors.checkout}
        callback={this.resetServerErrors}
      />
    );
    const isDelivery = order && order.fulfillmentType === SERVICE_TYPE.DELIVERY;
    const isCollection =
      order && order.fulfillmentType === SERVICE_TYPE.COLLECTION;

    const renderPriceSummary = order && (
      <PriceSummary
        basket={order}
        showDeliveryOnly={isDelivery}
        showCollectionOnly={isCollection}
        isCheckoutPage={true}
        showDailyLimit={this.showDailyLimit}
        navigateToBasket={this.navigateToBasket}
        dailyLimitNum={this.state.dailyLimitNum}
      />
    );
    const renderLoading = (
      <LoadingWrapper>
        <Loading isLight={false} />
      </LoadingWrapper>
    );
    const renderError = this.state.paymentError && (
      <PaymentErrorModal
        content={this.state.paymentError}
        callback={this.onPaymentErrorCallback}
        closeModalCallback={this.onPaymentErrorCallback}
        showModal={!!this.state.paymentError}
      />
    );
    const branchName =
      branchList &&
      basket &&
      basket.branchId &&
      branchList[basket.branchId] &&
      branchList[basket.branchId].name;
    const renderChosenSlotInfo = basket &&
      basket.fulfillAtSlot &&
      basket.fulfillmentStatus !== SLOT_FULFILMENT_STATUSES.DEFAULT_SLOT && (
        <Block>
          <BookSlotStatus
            fulfillAtSlot={basket.fulfillAtSlot}
            fulfillmentType={basket.fulfillmentType}
            isCheckoutPage={true}
            branchName={branchName}
          />
          <ChangeSlot onClick={this.bookSlot}>Change Slot</ChangeSlot>
        </Block>
      );
    const isChangingPayment =
      loadingProps && (loadingProps.loadingCheckout || loadingProps.settings);
    const renderCardPaymentButton = order &&
      !showGpIframe &&
      !showBraintreeButton &&
      !showAdyenDropin &&
      (order.paymentMethod === PAYMENT_TYPES.CDC.mode ||
        order.paymentMethod === PAYMENT_TYPES.BCDC.mode ||
        order.paymentMethod === PAYMENT_TYPES.PBB.mode) && (
        <TheButton onClick={this.processCardPayment}>
          {loading ? renderLoading : BUTTON_NAME.PAY_NOW}
        </TheButton>
      );
    const renderBrainTreeButton = !this.processingOrder &&
      showBtDropin &&
      showBraintreeButton && (
        <TheButton id="submit-button" onClick={this.sendNonceToServer}>
          {loadingProps.processBraintree ? renderLoading : BUTTON_NAME.PAY_NOW}
        </TheButton>
      );
    const renderCashPaymentButton = order &&
      order.paymentMethod !== PAYMENT_TYPES.CDC.mode &&
      order.paymentMethod !== PAYMENT_TYPES.BCDC.mode &&
      order.paymentMethod !== PAYMENT_TYPES.PBB.mode && (
        <TheButton onClick={this.onNonCardPaymentClick}>
          {loading ? renderLoading : BUTTON_NAME.PLACE_ORDER}
        </TheButton>
      );
    const renderGpIframe = showGpIframe && <GpIframe id="gpIframe" />;
    const renderBtDropin = showBtDropin && (
      <BraintreeDropIn>
        <div id="dropin-container" />
      </BraintreeDropIn>
    );

    const renderDelayAuthMsg = !!checkout && checkout.useDelayedAuth && (
      <DelayAuth>
        Note: As your slot is for a date more than 4 days away, we’ll
        periodically verify your payment to ensure it remains valid. You may
        temporarily see multiple checks on your bank statement, but rest
        assured, only one authorisation will be active.
      </DelayAuth>
    );
    const renderAdyenDropin = showAdyenDropin && (
      <AydenWrapper>
        {renderDelayAuthMsg}
        <AdyenContent
          id="dropin-adyen"
          lessPadding={!!checkout && checkout.useDelayedAuth}
        />
      </AydenWrapper>
    );
    const renderButtons = isChangingPayment ? (
      <ButtonLoading>{renderLoading}</ButtonLoading>
    ) : (
      <Fragment>
        {renderCardPaymentButton}
        {renderCashPaymentButton}
        {renderBrainTreeButton}
        <HiddenButton ref={this.payButtonRef} id="checkoutButton" />
      </Fragment>
    );
    const renderPaymentOptions = profile && order && basket && (
      <ContentBlock>
        <PaymentSelector
          paymentOptions={profile.paymentOptions}
          paymentMethod={order.paymentMethod}
          basketUuid={basket.uuid}
          promoTotal={promoTotal}
          slot={basket.fulfillAtSlot}
          fulfillmentType={basket.fulfillmentType}
          fulfillmentStatus={basket.fulfillmentStatus}
          isEditMode={basket.editMode}
          editOrderOriginalPaymentMethod={
            this.state.editOrderOriginalPaymentMethod
          }
          navToBasket={this.navigateToBasket}
        />
      </ContentBlock>
    );
    const renderPhoneNumber = paymentDetails &&
      paymentDetails.phoneNumber &&
      !!paymentDetails.phoneNumber.trim() && (
        <ContentLine>
          +{paymentDetails.phoneCountryCode} {paymentDetails.phoneNumber}
        </ContentLine>
      );
    const renderPaymentDetails = paymentDetails &&
      !isStaffAccount &&
      basket &&
      (basket.paymentMethod === PAYMENT_TYPES.CDC.mode ||
        basket.paymentMethod === PAYMENT_TYPES.BCDC.mode) && (
        <Block>
          <LeftBlock>
            <PaymentDetailsText>Your billing details:</PaymentDetailsText>
            <PaymentDetailsContent>
              <ContentLine>{paymentDetails.customerName}</ContentLine>
              <ContentLine>{paymentDetails.street1}</ContentLine>
              <ContentLine>{paymentDetails.street2}</ContentLine>
              <ContentLine>{paymentDetails.city}</ContentLine>
              <ContentLine>{paymentDetails.postalCode}</ContentLine>
              <ContentLine>{paymentDetails.emailAddress}</ContentLine>
              {renderPhoneNumber}
            </PaymentDetailsContent>
          </LeftBlock>
          <ChangeButtonWrapper>
            <Button
              onClick={this.openPaymentDetails}
              data-rw="basket--change-billing-details"
            >
              Change Billing Details
            </Button>
          </ChangeButtonWrapper>
        </Block>
      );
    const renderCashPaymentModal = showCashPaymentModal && (
      <CashPaymentModal
        branchName={branchName}
        isCollection={
          !!basket && basket.fulfillmentType === SERVICE_TYPE.COLLECTION
        }
        showModal={showCashPaymentModal}
        hasPbbPayment={hasPbbPayment}
        changeToCardPayment={this.changeToCardPayment}
        changeToPbbPayment={this.changeToPbbPayment}
        closeModalCallback={this.hideCashPaymentModal}
        callback={this.processNoCardPayment}
        promoTotal={promoTotal}
      />
    );
    const renderCardPaymentModal = showCardPaymentModal && (
      <CardPaymentModal
        showModal={showCardPaymentModal}
        hasPbbPayment={hasPbbPayment}
        changeToPbbPayment={this.changeToPbbPayment}
        closeModalCallback={this.hideCardPaymentModal}
        callback={this.processCardPayment}
        promoTotal={promoTotal}
      />
    );
    const renderTotalPayment = (
      <PaymentAmount>Payment amount: £{paymentTotalAmount}</PaymentAmount>
    );
    return (
      <Wrapper>
        {renderReLoginModal}
        <Head>
          <Title>Checkout</Title>
          <ButtonWrapper>
            <LinkButton to="/" nomargin={'true'}>
              Continue shopping
            </LinkButton>
          </ButtonWrapper>
        </Head>
        {renderServerErrors}
        {renderCheckoutErrors}
        {renderBraintreeErrors}
        {renderAdyenErrors}
        {renderAdyenCommercialCard}
        {renderError}
        <ContentWrapper>
          <CheckoutContent
            halfScreen={
              settings && settings.adyen === 'true' && showAdyenDropin
            }
          >
            <BorderContent>
              {renderChosenSlotInfo}
              {renderPaymentOptions}
              <Block ref={this.setRef}>{renderPriceSummary}</Block>
              {renderPaymentDetails}
            </BorderContent>
          </CheckoutContent>
          {renderTotalPayment}
          {renderGpIframe}
          {renderBtDropin}
          {renderAdyenDropin}
        </ContentWrapper>
        <ExtraWrapper alignRight={true}>{renderButtons}</ExtraWrapper>
        {renderProcessingModal}
        {renderSubmittingPaymentModal}
        {renderCashPaymentModal}
        {renderCardPaymentModal}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  basket: state.basket,
  checkout: state.checkout,
  auth: state.auth,
  errors: state.errors,
  profile: state.profile,
  settings: state.settings,
  branchList: state.branchList,
  nextRoute: state.nextRoute,
  order: state.order,
  route: state.route,
  loadingProps: state.loading,
  promoTotal: state.promoTotal,
  modal: state.modal,
  paymentDetails: state.paymentDetails,
  isStaffAccount: state.isStaffAccount,
  adyen: state.adyen,
});

const mapDispatchToProps = dispatch => ({
  getCheckoutData: bindActionCreators(getCheckoutData, dispatch),
  resetCheckout: bindActionCreators(resetCheckout, dispatch),
  authorizeOrder: bindActionCreators(authorizeOrder, dispatch),
  getBasket: bindActionCreators(getBasket, dispatch),
  resetError: bindActionCreators(resetError, dispatch),
  setAccount: bindActionCreators(setAccount, dispatch),
  setAuthToken: bindActionCreators(setAuth, dispatch),
  removeAuthToken: bindActionCreators(removeAuth, dispatch),
  removeLoginToken: bindActionCreators(removeLoginToken, dispatch),
  setCurrentRoute: bindActionCreators(setCurrentRoute, dispatch),
  setErrorManually: bindActionCreators(setErrorManually, dispatch),
  setPaymentType: bindActionCreators(setPaymentType, dispatch),
  setLoginToken: bindActionCreators(setLoginToken, dispatch),
  checkBasketSynced: bindActionCreators(checkBasketSynced, dispatch),
  toggleReloginModel: bindActionCreators(toggleReloginModel, dispatch),
  reloginByPassword: bindActionCreators(getTokenByPassword, dispatch),
  authBraintreePayment: bindActionCreators(authBraintreePayment, dispatch),
  authAdyenPayment: bindActionCreators(authAdyenPayment, dispatch),
  togglePaymentDetails: bindActionCreators(togglePaymentDetails, dispatch),
  getPaymentDetails: bindActionCreators(getPaymentDetails, dispatch),
  requestForceLogin: bindActionCreators(requestForceLogin, dispatch),
  getPbbBankDetails: bindActionCreators(getPbbBankDetails, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
